export const fr = {
    "n1": "première page",
    "n2": "Commande",
    "n3": "commerce",
    "n4": "équipe",
    "n5": "le mien",
    "t1": "Quelque chose s'est mal passé",
    "t2": "Copier avec succès",
    "t3": "Veuillez remplir les informations entièrement",
    "t4": "chargement…",
    "t5": "Connectez-vous avec succès",
    "t6": "Le score doit être supérieur à 3 avant de soumettre",
    "t7": "Soumission réussie",
    "t8": "Équilibre insuffisant, ne peut pas être collecté",
    "t9": "Échec de la soumission",
    't10': "Veuillez d'abord lier l'adresse de retrait",
    "t11": "L'adresse a été liée, veuillez contacter le service client pour le modifier",
    "t12": "La modification a réussi",
    "t13": "Modification a échoué",
    "t14": "Veuillez contacter le service client pour modifier votre mot de passe",
    "t15": "Erreur de format de mot de passe",
    "t16": "Retrait réussi",
    "t17": "Pour retirer de l'argent",
    "t18": "Retrait minimum en espèces",
    "t19": "Les mots de passe sont incohérents deux fois",
    "t20": "indice",
    "t21": "Je ne peux pas sauter pour le moment",
    "t22": "Erreur de format de mot de passe",
    "t23": "Enregistré avec succès",
    "t24": "Veuillez saisir le code régional",
    "t25": "Pas encore",
    "l1": "langue",
    "l2": "Veuillez saisir votre numéro de compte",
    "l3": "Veuillez saisir votre mot de passe",
    "l4": "souviens-toi",
    "l5": "Se connecter",
    "l6": "Aller s'inscrire",
    "l7": "Pas de compte",
    "l8": "Numéro de téléphone",
    "l9": "Surnom",
    "l10": "mot de passe",
    "l11": "Confirmez le mot de passe",
    "l12": "Code d'invitation",
    "l13": "registre",
    "l14": "Avoir déjà un compte",
    "l15": "Aller se connecter",
    "l16": "Veuillez saisir un mot de passe à 6 à 16 chiffres",
    "bz1": "aide",
    "bz2": "Questions fréquemment posées",
    "bz3": "Règles commerciales",
    "bz4": "Comment retirer de l'argent",
    "bz5": "Comment recharger",
    "bz6": "1. Qu'est-ce que le robot Amazon Review?",
    "bz7": "Amazon Review Robot est une société de promotion marketing qui aide Amazon à réviser les marchands de boutiques en ligne du robot du monde entier à obtenir plus de ventes de commandes et à augmenter les données de navigation pour la boutique en ligne Amazon Review Robot. Nous nous engageons à établir un modèle de promotion marketing rentable en trois parties entre Amazon Review Robot et les commerçants et les consommateurs. Combinant la dernière technologie de blockchain P2P, connectez rapidement les consommateurs et les commerçants via USDT (TRC20, ERC20). Les utilisateurs enregistrés peuvent recevoir des commissions sur les commandes, tandis que les commerçants peuvent augmenter les données de vente de magasin. Il s'agit du dernier modèle de profit dans le modèle d'Internet Blockchain!",
    "bz8": "2.Comment Amazon Review Robot fonctionne-t-il?",
    "bz9": "Grâce aux commentaires quotidiens de l'Amazon Review Robot, il montre que lorsque la vente de produits qui ont besoin d'améliorations sont vendues sur Amazon Review Robot, les utilisateurs doivent seulement cliquer facilement sur la commande et le système générera automatiquement des abonnements de commande. Les utilisateurs paient les montants de la commande via la blockchain USDT et reçoivent des commissions quotidiennes.",
    "bz10": "3. Pourquoi y a-t-il une différence dans les prix de la même devise dans les deux transactions?",
    "bz11": "Les différences de prix sont causées par tous les facteurs qui ne sont pas propices à la circulation libre des devises, y compris la vitesse de transfert de devises, les conditions du réseau, les restrictions d'accès des devises, la reconnaissance par les personnes de la monnaie par différentes régions et même les types de paires de transactions fournies par les échanges et les transactions. Par conséquent, la même devise peut créer des différences de prix dans différentes transactions.",
    "bz12": "4. Profit d'investissement?",
    "bz13": "Plus le prix de l'article que vous obtenez est élevé, plus le bénéfice que vous obtenez dans la commande est élevé. Pendant ce temps, Amazon Review Robot alloue au hasard de grands ordres de commission.",
    "bz14": "Chaque jour, le système génère et distribue automatiquement 60 commandes de produits aux utilisateurs. Les grands ordres de commission sont alloués au hasard.",
    "bz15": "L'USDT peut retirer de l'argent après 60 commandes. (Si 60 commandes ne sont pas terminées, le système s'arrêtera automatiquement au volume de commande restant de la journée)",
    "bz16": "Chaque compte ne peut être lié qu'à un compte USDT.",
    "bz17": "Après avoir terminé la livraison de la commande quotidienne, vous pouvez retirer de l'argent normalement et arriver dans les 24 heures suivant le début du retrait, et il n'y a pas de restrictions de retrait.",
    "bz18": "Avant la recharge, veuillez lier votre adresse de retrait (prend en charge l'adresse USDT TRC-20).",
    "bz19": "La recharge ou le transfert bancaire ou le transfert d'échange sont interdits entre les comptes connexes.",
    "ty1": "Bien sûr",
    "ty2": "Annuler",
    "ty3": "soumettre",
    "ty4": "adresse",
    "s1": "Revenu cumulatif",
    "s2": "Servir",
    "s3": "à propos de nous",
    "s4": "aide",
    "s5": "Partenaires",
    "s6": "Mon service",
    "d1": "Non terminé",
    "d2": "Complété",
    "d3": "Pas encore de données",
    "d4": "Numéro unique",
    "d5": "Temps de négociation",
    "d6": "Montant",
    "d7": "Nombre actuel de tâches",
    "d8": "Gains attendus",
    "d9": "Besoin",
    "d10": "score",
    "j1": "Solde du compte",
    "j2": "Introduction de la transaction",
    "j3": "Amazon Review Robot Utilisez les commentaires du robot Amazon Review quotidiennement pour présenter les produits que le robot Amazon Review vend ayant besoin d'amélioration. L'utilisateur doit simplement cliquer sur la commande et le système générera automatiquement la signature de la commande. Les utilisateurs paient le montant de la commande via la blockchain USDT et reçoivent une commission de plus de 0,6% à chaque fois.",
    "j4": "Commencer à faire correspondre",
    "j5": "Les revenus d'aujourd'hui",
    "j6": "Numéro terminé",
    "j7": "Nombre total de tâches",
    "td1": "Équilibre",
    "td2": "commission",
    "td3": "recevoir",
    "td4": "Nombre total de personnes",
    "td5": "utilisateur",
    "td6": "contribuer",
    "td7": "quantité",
    "td8": "État",
    "g1": "Retrait",
    "g2": "se débarrasser de",
    "g3": "Inviter des amis",
    "g4": "Inviter maintenant",
    "g5": "Vous recevrez une proportion de commission correspondante pour chaque bénéfice que vous gagnez après avoir enregistré un ami",
    "g6": "information personnelle",
    "g7": "Détails de financement",
    "g8": "Record de recharge",
    "g9": "Dossier de retrait",
    "g10": "langue",
    "g11": "Déconnecter",
    "g12": "Êtes-vous sûr de vous déconnecter?",
    "yh1": "Informations sur l'utilisateur",
    "yh2": "Réviser",
    "yh3": "installation",
    "yh4": "Téléphone",
    "yh6": "Mot de passe d'origine",
    "yh7": "Nouveau mot de passe",
    "mx1": "Recevoir une commission",
    "mx2": "revenu",
    "mx3": "Refus de retrait",
    "mx4": "temps",
    "mx5": "Recharger avec succès",
    "mx6": "Rejeté",
    "mx7": "En attente",
    "mx8": "Adresse de retrait",
    "mx9": "Solde disponible",
    "mx10": "Réception réelle",
    "mx11": "Veuillez saisir le montant du retrait",
    "cz1": "L'adresse de dépôt ne prend en charge que ERC20, le montant minimum de dépôt est de 20USDT",
    "cz2": "copie",
    "cz3": "Sélection de monnaie philippine",
    "cz4": "Payer",
    "cz5": "L'adresse de dépôt ne prend en charge que TRC20-USDT, et le montant du dépôt minimum est de 20USDT.",
    "cz6": "L'adresse de dépôt ne prend en charge que ERC20, le montant minimum de dépôt est de 20USDT",
    "cz10": "L'adresse de dépôt ne prend en charge que BTC",
    "qy1": "Code régional",
    "yq1": "Inviter des amis",
    "yq2": "Code d'invitation",
    "yq3": "Invitez des amis et profitez de grands bénéfices",
    "yq4": "Aller inviter",
    "new1": "Mode de paiement"
  }
  