import { React, useState, useEffect } from 'react'
import Tabber from '../copmonets/Tabber'
import { Toast, Picker } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import './user.css'
import { getInfo } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function User() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)//选择器显示
    const [defaultHead, setDefaultHead] = useState('/assets/index/headDefault1.svg')
    const [userInfo, setUserInfo] = useState({})
    const [orderMoney, setOrderMoney] = useState()
    const [show, setShow] = useState(false)
    const [list1, setlist1] = useState([
        [
            { label: '中文', value: 'zh' },
            { label: 'English', value: 'en' },
            { label: 'Русский', value: 'ru' },
            { label: 'Français', value: 'fr' },
            { label: 'svenska', value: 'sv' },
            { label: 'suomalainen', value: 'fi' },
            { label: 'magyar', value: 'hu' },
            { label: "o'zbek", value: 'uz' },
            { label: "Кыргызча", value: 'ky' },
            { label: 'فارسی', value: 'fa' },
            { label: 'Español', value: 'es' },
            { label: 'Português', value: 'pt' },
            { label: 'Polski', value: 'pl' },
            { label: 'čeština', value: 'cs' },
            { label: 'Dansk', value: 'da' },
            { label: 'Ελληνικά', value: 'el' },
            { label: 'Română', value: 'ro' },
            { label: 'Slovenský', value: 'sk' },
            { label: 'Deutsch', value: 'de' },
            { label: 'Italiano', value: 'it' },
            { label: 'やまと', value: 'jp' },
            { label: 'Türkçe', value: 'tr' },
            { label: 'العربية', value: 'ar' },
            { label: 'Nederlands', value: 'nl' },
            { label: 'বাংল', value: 'bn' },
            { label: '한국인', value: 'kr' },
        ],
    ])

    const getData = async () => {
        const res = await getInfo({})
        if (res.code == 1) {
            switch (res.data.headimg) {
                case '1':
                    setDefaultHead('/assets/index/headDefault1.svg')
                    break;
                case '2':
                    setDefaultHead('/assets/index/headDefault2.svg')
                    break;
                case '3':
                    setDefaultHead('/assets/index/headDefault3.svg')
                    break;
                case '4':
                    setDefaultHead('/assets/index/headDefault4.svg')
                    break;
                case '5':
                    setDefaultHead('/assets/index/headDefault5.svg')
                    break;
                default:
                    setDefaultHead('/assets/index/headDefault1.svg')
                    break;
            }
            setUserInfo(res.data)
            // 判断是否有未完成订单，有的话当前余额减去冻结金额，没有的话正常显示余额
            if (res.data.freeze_gec !== '0.00') {
                //有未完成订单，余额减去订冻结的金额
                setOrderMoney((Number(res.data.balances) - Number(res.data.freeze_gec)).toFixed(2))
            } else {
                //正常余额
                setOrderMoney(Number(res.data.balances));
            }
        } else {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('warning');
            localStorage.removeItem('tan');
            localStorage.removeItem('tabber');
            Toast.show({
                icon: 'fail',
                content: res.info,
            })
            setTimeout(() => {
                window.location.hash = "/login"
            }, 2500);
        }
    }
    const jump = (val) => {
        navigate(val)
    }
    const outLogin = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        localStorage.removeItem('warning');
        localStorage.removeItem('tan');
        localStorage.removeItem('tabber');
        Toast.show({
            icon: 'loading',
            content: t('t4'),
            duration: 800
        })
        setTimeout(() => {
            navigate('/Login')
        }, 1000);
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='user'>
            <div className='user_top'>
                <div className='user_info'>
                    <img src={defaultHead} alt="" />
                    <div className='info_text'>
                        <p>{userInfo.nickname}</p>
                        <div className='user_id'>
                            <p>ID:{userInfo.invite}</p>
                            <img src="/assets/index/copy.svg" alt="" onClick={() => {
                                navigator.clipboard.writeText(userInfo.invite).then(() => {
                                    Toast.show({
                                        icon: 'success',
                                        content: t('t2'),
                                    })
                                });
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='user_box'>
                <div className='user_fun'>
                    <div className='money_num'>
                        <div className='user_usdts'>
                            <img src="/assets/index/smalllogo.svg" alt="" />
                            <p>{orderMoney}</p>
                            <p>USDT</p>
                        </div>
                        <img src="/assets/index/frash1.svg" alt="" onClick={() => {
                            Toast.show({
                                icon: 'loading',
                                content: t('t4'),
                                duration: 1000,
                            })
                            getData()
                        }} />
                    </div>
                    <div className='user_but'>
                        <p className='but_click but1' onClick={() => {
                            if (userInfo.uget_address_trc != '') {
                                navigate(`/Withdraw`)
                            } else {
                                navigate('/UserInfo')
                                Toast.show({
                                    content: t('t10'),
                                })
                            }
                        }}>{t('g1')}</p>
                        <p className='but_click but2' onClick={() => {
                            if (userInfo.has_btc == 0 && userInfo.has_erc == 0) {
                                navigate('/UserPay')
                            } else {
                                navigate('/NewPay')
                            }
                        }}>{t('g2')}</p>
                    </div>
                </div>
                <div className='user_invite'>
                    <div className='invite_text'>
                        <p style={{ fontSize: '16px', margin: '0 0 5px', color: '#000' }}>{t('g3')}</p>
                        <p>{t('g5')}</p>
                    </div>
                    <p onClick={() => { navigate(`/Invite`, { state: { invite: userInfo.invite } }) }}>{t('g4')}</p>
                </div>
                <div className='user_list'>
                    <div className='user_list_item' onClick={() => { jump('/UserInfo') }}>
                        <div className='list_item_name'>
                            <img src="/assets/index/edit.svg" alt="" />
                            <p>{t('g6')}</p>
                        </div>
                        <RightOutline />
                    </div>
                    <div className='user_list_item' onClick={() => { navigate('/Funds') }}>
                        <div className='list_item_name'>
                            <img src="/assets/index/jifenmingxi.svg" alt="" />
                            <p>{t('g7')}</p>
                        </div>
                        <RightOutline />
                    </div>
                    <div className='user_list_item' onClick={() => { navigate('/Recharge') }}>
                        <div className='list_item_name'>
                            <img src="/assets/index/huiyuanchongzhi.svg" alt="" />
                            <p>{t('g8')}</p>
                        </div>
                        <RightOutline />
                    </div>
                    <div className='user_list_item' onClick={() => { navigate('/Withdraws') }}>
                        <div className='list_item_name'>
                            <img src="/assets/index/tixian.svg" alt="" />
                            <p>{t('g9')}</p>
                        </div>
                        <RightOutline />
                    </div>
                    <div className='user_list_item' onClick={() => { setVisible(true) }}>
                        <div className='list_item_name'>
                            <img src="/assets/index/ziranyuyanchuli.svg" alt="" />
                            <p>{t('g10')}</p>
                        </div>
                        <RightOutline />
                    </div>
                </div>
                <div className='Logout' onClick={() => { setShow(true) }}>
                    <p>{t('g11')}</p>
                </div>
                <div style={{ height: "60px" }}></div>
            </div>
            {
                show && <div className='modelWindow'>
                    <div className='window_center'>
                        <div className='outLogin'>
                            <p>{t('g12')}?</p>
                            <div className='outLogin_fun'>
                                <p onClick={() => { setShow(false) }}>{t('ty2')}</p>
                                <p onClick={outLogin}>{t('ty1')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Picker
                columns={list1}
                defaultValue={[localStorage.getItem('lang') || 'en']}
                cancelText={t('ty2')}
                confirmText={t('ty1')}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                onConfirm={v => {
                    i18n.changeLanguage(v[0])
                    localStorage.setItem('lang', v[0])
                }}
            />
            <Tabber></Tabber>
        </div>
    )
}
