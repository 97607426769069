//es西班牙
export const es = {
    //Barra de navegación
    'n1': 'Inicio',
    'n2': 'Orden',
    'n3': 'Transacción',
    'n4': 'Equipo',
    'n5': 'mi',
    //clase rápida
    't1': 'Ocurrió un error',
    't2': 'Copia exitosa',
    't3': 'Por favor completa la información completamente',
    't4': 'Cargando...',
    't5': 'Inicio de sesión exitoso',
    't6': 'La puntuación debe ser mayor que 3 para enviar',
    't7': 'Envío exitoso',
    't8': 'Saldo insuficiente, no se puede recibir',
    't9': 'El envío falló',
    't10': 'Primero vincule la dirección de retiro',
    't11': 'La dirección ha sido vinculada, comuníquese con el servicio de atención al cliente para modificarla',
    't12': 'Modificación exitosa',
    't13': 'Error en la modificación',
    't14': 'Por favor, póngase en contacto con el servicio de atención al cliente para cambiar su contraseña',
    't15': 'Error de formato de contraseña',
    't16': 'Retiro exitoso',
    't17': 'Retiros máximos',
    't18': 'Retiro mínimo',
    't19': 'Dos contraseñas son inconsistentes',
    't20': 'Avisar',
    't21': 'No se puede saltar temporalmente',
    't22': 'Error de formato de contraseña',
    't23': 'Registro exitoso',
    't24': 'Por favor ingrese el código de área',
    't25': 'No más',
    //inscribirse
    'l1': 'Idioma',
    'l2': 'Por favor ingrese su número de cuenta',
    'l3': 'Por favor ingrese la contraseña',
    'l4': 'recordar',
    'l5': 'Iniciar sesión',
    'l6': 'Ir al registro',
    'l7': 'Sin cuenta',
    'l8': 'Número de teléfono móvil',
    'l9': 'apodo',
    'l10': 'contraseña',
    'l11': 'Confirmar contraseña',
    'l12': 'código de invitación',
    'l13': 'Registrarse',
    'l14': 'Ya tengo una cuenta',
    'l15': 'Ir a iniciar sesión',
    'l16': 'Ingrese una contraseña de 6 a 16 dígitos',

    //ayuda
    'bz1': 'Ayuda',
    'bz2': 'Preguntas frecuentes',
    'bz3': 'Reglas comerciales',
    'bz4': 'Cómo retirar dinero',
    'bz5': 'Cómo recargar',
    'bz6': '1.¿Qué es Amazon Review RoBoT?',
    'bz7': 'Amazon Review RoBoT es una empresa de marketing y promoción que ayuda a los comerciantes de la tienda en línea Amazon Review RoBoT de todo el mundo a obtener más pedidos de ventas y aumentar los datos de navegación de la tienda en línea Amazon Review RoBoT. Estamos comprometidos a establecer un modelo tripartito de promoción y marketing rentable entre Amazon Review RoBoT, comerciantes y consumidores. Combinado con la última tecnología blockchain P2P, los consumidores y comerciantes se conectan rápidamente a través de USDT (TRC20, ERC20). Los usuarios registrados pueden obtener comisiones por los pedidos, mientras que los comerciantes pueden aumentar los datos de ventas de su tienda. ¡Es el último modelo de ganancias en el modelo blockchain de Internet! ',
    'bz8': '2.¿Cómo funciona Amazon Review RoBoT?',
    'bz9': "Los comentarios diarios de Amazon Review RoBoT muestran que los productos que necesitan mejoras se venden en Amazon Review RoBoT. Los usuarios solo necesitan hacer clic fácilmente en el pedido y el sistema genera automáticamente una suscripción al pedido. Los usuarios pagan el monto del pedido a través de blockchain USDT y reciben comisiones diarias. ",
    'bz10': "3. ¿Por qué los precios de la misma moneda son diferentes en dos transacciones? ",
    'bz11': 'Las diferencias de precios son causadas por todos los factores que no favorecen la libre circulación de divisas, incluida la velocidad de las transferencias de divisas, las condiciones de la red, las restricciones de acceso a las divisas, el reconocimiento de divisas por parte de personas en diferentes regiones e incluso los pares comerciales. proporcionado por tipos de intercambios, transacciones, etc. Por tanto, una misma moneda puede tener diferencias de precio en diferentes transacciones. ',
    'bz12': '4.¿Beneficio de la inversión? ',
    'bz13': 'Cuanto mayor sea el precio del producto que obtenga, mayor será la ganancia del pedido que obtendrá. Al mismo tiempo, Amazon Review RoBoT distribuye aleatoriamente pedidos con grandes comisiones. ',
    'bz14': 'Todos los días, el sistema genera y distribuye automáticamente 60 pedidos de productos a los usuarios. Los usuarios completan el pago de cada pedido a través de USDT y reciben una comisión del 0.6%. Los pedidos con grandes comisiones se asignan aleatoriamente. ',
    'bz15': 'El USDT se puede retirar después de 60 pedidos. (Si no se completan 60 pedidos, el sistema se detendrá automáticamente hasta completar los pedidos restantes del día)',
    'bz16': 'Cada cuenta sólo puede vincularse a una cuenta USDT. Si ocurre un error, comuníquese con el servicio de atención al cliente',
    'bz17': 'Después de completar la entrega de los pedidos diarios, puedes retirar dinero normalmente. Llegará dentro de las 24 horas posteriores al inicio del retiro y no hay límite de retiro',
    'bz18': 'Por favor vincule su dirección de retiro (compatible con TRC-20) y la dirección USDT antes de recargar. Puede hacer clic en Recargar en la APLICACIÓN o en la página web y seleccionar la cadena de bloques (TRC-20) que está utilizando para recargar. ',
    'bz19': "Está prohibido realizar recargas mutuas, transferencias bancarias, transferencias de cambio, etc. entre cuentas relacionadas. Este comportamiento se sospecha de apropiación de pedidos maliciosos. Si se encuentra dicho comportamiento, la cuenta será prohibida.",
    //texto del módulo
    //Palabras comunes
    'ty1': 'OK',
    'ty2': 'Cancelar',
    'ty3': 'Enviar',
    'ty4': 'dirección',
    //página delantera
    's1': 'Ingresos acumulados',
    's2': 'Servicio',
    's3': 'Acerca de nosotros',
    's4': 'Ayuda',
    's5': 'Socio',
    's6': 'Mi servicio',
    //Orden
    'd1': 'Sin terminar',
    'd2': 'Completo',
    'd3': 'Aún no hay datos',
    'd4': 'número impar',
    'd5': 'Tiempo de negociación',
    'd6': 'Cantidad',
    'd7': 'Número actual de tareas',
    'd8': 'Retorno esperado',
    'd9': 'Necesito más',
    'd10': 'Calificación',
    //comercio
    'j1': 'Saldo de la cuenta',
    'j2': 'Introducción comercial',
    'j3': 'Amazon Review RoBoT utiliza los comentarios de Amazon Review RoBoT todos los días para mostrar los productos vendidos por Amazon Review RoBoT que necesitan mejoras. Los usuarios sólo necesitan hacer clic en el pedido y el sistema generará automáticamente una firma de pedido. Los usuarios pagan el monto del pedido a través de la cadena de bloques USDT y pueden recibir más del 0,6% de comisión cada vez, y el sistema distribuye aleatoriamente grandes pedidos de recompensa. ',
    'j4': 'Comenzar a hacer coincidir',
    'j5': 'Ganancias de hoy',
    'j6': 'Número completado',
    'j7': 'Número total de tareas',
    //equipo
    'td1': 'Saldo',
    'td2': 'comisión',
    'td3': 'Recibir',
    'td4': 'Número total de personas',
    'td5': 'usuario',
    'td6': 'contribución',
    'td7': 'Cantidad',
    'td8': 'estado',
    //centro personal
    'g1': 'Retirar',
    'g2': 'Recargar',
    'g3': 'Invitar amigos',
    'g4': 'Invitar ahora',
    'g5': 'Por cada beneficio que obtenga tu amigo después de registrarse, recibirás la proporción correspondiente de comisión',
    'g6': 'Información personal',
    'g7': 'Detalles del fondo',
    'g8': 'Registro de recarga',
    'g9': 'Registro de retiro',
    'g10': 'Idioma',
    'g11': 'Cerrar sesión',
    'g12': '¿Estás seguro de que deseas cerrar sesión?',
    //Información de usuario
    'yh1': 'Información del usuario',
    'yh2': 'Modificar',
    'yh3': 'configuración',
    'yh4': 'teléfono',
    'yh5': '¿Estás seguro de que deseas cerrar sesión?',
    'yh6': 'Contraseña original',
    'yh7': 'Nueva contraseña',
    //Detalles
    'mx1': 'Cobro de comisiones',
    'mx2': 'beneficio',
    'mx3': 'Retiro rechazado',
    'mx4': 'tiempo',
    'mx5': 'Recarga exitosa',
    'mx6': 'Rechazado',
    'mx7': 'pendiente',
    'mx8': 'Dirección de retiro',
    'mx9': 'Saldo disponible',
    'mx10': 'Llegada real',
    'mx11': 'Por favor ingrese el monto del retiro',
    //completar
    'cz1': 'La dirección de depósito solo admite ERC20 y el monto mínimo de depósito es 20 USDT',
    'cz2': 'Copiar',
    'cz3': 'Selección de moneda Fiat',
    'cz4': 'pago',
    'cz5': 'La dirección de depósito solo admite TRC20-USDT y el monto mínimo de depósito es 20USDT',
    'cz6': 'La dirección de depósito solo admite ERC20 y el monto mínimo de depósito es 20 USDT',
    'cz1': 'La dirección de depósito solo admite ERC20 y el monto mínimo de depósito es 20 USDT',
    'cz1': 'La dirección de depósito solo admite ERC20 y el monto mínimo de depósito es 20 USDT',
    "cz10": "La dirección de depósito sólo admite BTC",
    //área
    'qy1': 'código de área',
    'yq1': 'Invitar amigos',
    'yq2': 'código de invitación',
    'yq3': 'Invita amigos y disfruta de grandes beneficios',
    'yq4': 'Ve e invita',
    "new1": "Método de pago"
}